import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import logo from "./logo.svg"; // Adjust the path as needed

const Logo = ({ logoClass }) => {
  return (
    <div className={clsx(logoClass)} style={{ marginBottom: "15px" }}>
      <Link to={(process.env.PUBLIC_URL || "") + "/"}>
        <img src={logo} alt="Company Logo" className="svg-class" />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  logoClass: PropTypes.string,
};

export default Logo;
