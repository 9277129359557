import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Rating from "./sub-components/ProductRating";
import { getDiscountPrice } from "../../helpers/product";
import ProductModal from "./ProductModal";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { Button } from "react-bootstrap";

const ProductGridSingle = ({
  product,
  currency,
  cartItem,
  wishlistItem,
  compareItem,
  spaceBottomClass,
  isRequestSample = false,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);
  const dispatch = useDispatch();
  const otherDescriptionList = product.otherDescriptionList;

  useEffect(() => {
    if (currency.currencyName === "isCartDetailsModalOpen") {
      setModalShow(false);
    }
  }, [currency]);

  return (
    <Fragment>
      {isRequestSample ? (
        <Fragment>
          <ProductModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            product={product}
            currency={currency}
            discountedPrice={discountedPrice}
            finalProductPrice={finalProductPrice}
            finalDiscountedPrice={finalDiscountedPrice}
            wishlistItem={wishlistItem}
            compareItem={compareItem}
            otherDescriptionList={otherDescriptionList}
          />

          <Fragment>
            <div className={clsx("product-wrap", spaceBottomClass)}>
              <div className="product-img">
                <div onClick={() => setModalShow(true)}>
                  <img
                    className="default-img"
                    src={process.env.PUBLIC_URL + product.image[0]}
                    alt=""
                  />
                  {product.image.length > 1 ? (
                    <img
                      className="hover-img"
                      src={process.env.PUBLIC_URL + product.image[1]}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </div>
                {product.discount || product.new ? (
                  <div className="product-img-badges">
                    {product.discount ? (
                      <span className="pink">-{product.discount}%</span>
                    ) : (
                      ""
                    )}
                    {product.new ? <span className="purple">New</span> : ""}
                  </div>
                ) : (
                  ""
                )}

                <div className="product-action ">
                  <div
                    className="pro-same-action pro-cart"
                    style={{ width: "100%", display: "flex", gap: "8px" }}
                  >
                    {product.affiliateLink && (
                      <Button
                        variant="primary"
                        style={{
                          textAlign: "center",
                          paddingBottom: "10px",
                        }}
                        onClick={() => setModalShow(true)}
                      >
                        See Details{" "}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="product-content text-center">
                <h3>{product.name}</h3>
                {product.rating && product.rating > 0 ? (
                  <div className="product-rating">
                    <Rating ratingValue={product.rating} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* product modal */}
          </Fragment>
        </Fragment>
      ) : (
        <Fragment>
          <div className={clsx("product-wrap", spaceBottomClass)}>
            <div className="product-img">
              <a
                href={product.affiliateLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="default-img"
                  src={process.env.PUBLIC_URL + product.image[0]}
                  alt=""
                />
                {product.image.length > 1 ? (
                  <img
                    className="hover-img"
                    src={process.env.PUBLIC_URL + product.image[1]}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </a>
              {product.discount || product.new ? (
                <div className="product-img-badges">
                  {product.discount ? (
                    <span className="pink">-{product.discount}%</span>
                  ) : (
                    ""
                  )}
                  {product.new ? <span className="purple">New</span> : ""}
                </div>
              ) : (
                ""
              )}

              <div className="product-action">
                <div
                  className="pro-same-action pro-cart"
                  style={{ width: "100%" }}
                >
                  {product.affiliateLink && (
                    <a
                      href={product.affiliateLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Buy now{" "}
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="product-content text-center">
              <h3>{product.name}</h3>
              {product.rating && product.rating > 0 ? (
                <div className="product-rating">
                  <Rating ratingValue={product.rating} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* product modal */}
        </Fragment>
      )}
    </Fragment>
  );
};

ProductGridSingle.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  wishlistItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default ProductGridSingle;
