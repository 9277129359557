import React, { useState, useEffect, useRef, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import "./DigitalLabelsPage.scss"; // Include custom styles

const labelMappings = {
  "classic-milk-tea": "Classic Milk Tea",
  boba: "Boba",
};

const labels = [
  {
    productName: "Classic Milk Tea",
    images: [
      {
        name: "Front Label",
        url: "/assets/img/Milktea-01.jpg",
        alt: "Classic Milk Tea Front Label Image",
      },
      {
        name: "Back Label",
        url: "/assets/img/Milktea-02.jpg",
        alt: "Classic Milk Tea Back Label Image",
      },
    ],
  },
  {
    productName: "Boba",
    images: [
      {
        name: "Front Label",
        url: "/assets/img/boba-01.jpg",
        alt: "Boba Front Label Image",
      },
      {
        name: "Back Label",
        url: "/assets/img/boba-02.jpg",
        alt: "Boba Back Label Image",
      },
    ],
  },
];

const DigitalLabelsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const location = useLocation();
  const highlightedRef = useRef(null);

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setCurrentImage(null);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const productParam = params.get("product");
    const productName = labelMappings[productParam];
    if (productName) {
      const element = document.getElementById(
        productName.replace(/\s+/g, "-").toLowerCase()
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        highlightedRef.current = element;
        element.classList.add("highlight");
      }
    }
    return () => {
      if (highlightedRef.current) {
        highlightedRef.current.classList.remove("highlight");
      }
    };
  }, [location]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Digital Labels"
        description="Digital Labels for Products."
      />
      <LayoutOne headerTop="visible">
        <Container className="my-5">
          <Row className="justify-content-center">
            {labels.map((label, index) => (
              <Col md={6} key={index}>
                <Card className="shadow-sm mb-4">
                  <Card.Body
                    id={label.productName.replace(/\s+/g, "-").toLowerCase()}
                  >
                    <Card.Title className="text-center mb-4">
                      {label.productName}
                    </Card.Title>
                    <Row>
                      {label.images.map((image, i) => (
                        <Col xs={6} key={i} className="text-center">
                          <Card.Subtitle className="mb-2">
                            {image.name}
                          </Card.Subtitle>
                          <div className="image-container">
                            <img
                              src={image.url}
                              alt={image.alt}
                              className="img-fluid rounded shadow-sm cursor-pointer"
                              onClick={() => handleImageClick(image)}
                            />
                            <div
                              className="overlay"
                              onClick={() => handleImageClick(image)}
                            >
                              Click to enlarge
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          {/* Fullscreen Modal for Image */}
          {isOpen && currentImage && (
            <div className="fullscreen-modal" onClick={handleCloseModal}>
              <img
                src={currentImage.url}
                alt={currentImage.alt}
                className="fullscreen-image"
              />
              <Button
                variant="danger"
                onClick={handleCloseModal}
                className="close-button"
              >
                Close
              </Button>
            </div>
          )}
        </Container>
      </LayoutOne>
    </Fragment>
  );
};

export default DigitalLabelsPage;
