import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrency } from "../../../store/slices/currency-slice";

const LanguageCurrencyChanger = ({ currency }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const changeLanguageTrigger = (e) => {
    const languageCode = e.target.value;
    i18n.changeLanguage(languageCode);
  };

  const setCurrencyTrigger = (e) => {
    const currencyName = e.target.value;
    dispatch(setCurrency(currencyName));
  };

  return (
    <div
      className="language-currency-wrap"
      style={{
        paddingTop: "25px",
        paddingBottom: "25px",
      }}
    >
      <div className="same-language-currency">
        <p>
          <strong>Contact us at </strong>
          <strong>
            <a href="mailto:premiumqualityllc@outlook.com?subject=Inquiry&body=Hello%20there!">
              Premiumqualityllc@outlook.com
            </a>
          </strong>
        </p>
      </div>
    </div>
  );
};

LanguageCurrencyChanger.propTypes = {
  currency: PropTypes.shape({}),
};

export default LanguageCurrencyChanger;
