import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import "./temp.scss";
import BobaPostsNoSidebar from "../../wrappers/blog/BobaPealrNoSidebar";
// import SectionTitle from "../../components/section-title/SectionTitle";
import SectionTitle from "../../components/section-title/SectionTitleTwo";
const BobaNoSidebar = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Boba Pearl Instructions"
        description="Blog of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <SectionTitle
          titleText="Boba Pearl Instructions"
          positionClass="text-center"
          spaceClass="mt-55 mb-10"
        />
        <div className="blog-area pt-100 pb-100 blog-no-sidebar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tempClassClass">
                  <div className="row">
                    {/* blog posts */}
                    <BobaPostsNoSidebar />
                  </div>

                  {/* blog pagination */}
                  {/*<BlogPagination />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default BobaNoSidebar;
