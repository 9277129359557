import PropTypes from "prop-types";
import clsx from "clsx";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SectionTitle from "../../components/section-title/SectionTitle";
import ProductGrid from "./ProductGrid";
import SectionTitleThree from "../../components/section-title/SectionTitleThree";

const TabProduct = ({
  spaceTopClass,
  spaceBottomClass,
  bgColorClass,
  category,
  hasTitle = true,
  hasMarginTop = true,
  isRequestSample = false,
}) => {
  return (
    <div
      className={clsx(
        "product-area",
        spaceTopClass,
        spaceBottomClass,
        bgColorClass
      )}
    >
      <div
        className="container"
        style={{ marginTop: hasMarginTop ? "70px" : "0px" }}
      >
        {hasTitle && (
          <SectionTitleThree
            titleText="Featured Products"
            positionClass="text-center"
          />
        )}

        <Tab.Container defaultActiveKey="men">
          <Nav
            variant="pills"
            className={
              hasMarginTop
                ? "product-tab-list pt-30 pb-55 text-center"
                : "product-tab-list text-center"
            }
          >
            {hasTitle && (
              <h2>
                Great Tasting! Easy to Prepare and crafted for lower sugar and
                lower calorie!
              </h2>
            )}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="men">
              <div className="row">
                <ProductGrid
                  category={category}
                  type="men"
                  limit={8}
                  spaceBottomClass="mb-25"
                  isRequestSample={isRequestSample}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

TabProduct.propTypes = {
  bgColorClass: PropTypes.string,
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default TabProduct;
