import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import logo from "./logo.svg";
const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo" style={{ marginBottom: "15px" }}>
        <Link to={process.env.PUBLIC_URL + "/"}>
          <img src={logo} alt="Company Logo" className="svg-class" />
        </Link>
      </div>
      <p>
        &copy; {new Date().getFullYear()}{" "}
        <a
          href="https://hasthemes.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Health Tea Boba
        </a>
        .<br /> All Rights Reserved
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
