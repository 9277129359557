import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileNavMenu = () => {
  const { t } = useTranslation();

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>{t("home")}</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>{t("about_us")}</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/drinks-instruction"}>
            {t("Drinks Instruction")}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/boba-pearls-instruction"}>
            {t("Boba Pearls Instruction")}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/digital-labels"}>
            {t("Digital Labels")}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/social-media-links"}>
            {t("Social Media Links")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
