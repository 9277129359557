const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info" style={{ marginBottom: "20px" }}>
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-envelope"></i>
              <span>
                <strong>Contact us:</strong>
              </span>{" "}
              <a href="mailto:premiumqualityllc@outlook.com">
                Premiumqualityllc@outlook.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div
        className="off-canvas-widget-social"
        style={{ marginBottom: "90px" }}
      >
        <div style={{ flexDirection: "column", display: "flex" }}>
          <span>
            <strong>Social Media </strong>{" "}
          </span>{" "}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a href="//www.instagram.com/healthtea_boba/" title="Instagram">
              <i className="fa fa-instagram"></i>
            </a>
            <a href="//www.facebook.com/HealthTeaBoba.USA/" title="Facebook">
              <i className="fa fa-facebook"></i>
            </a>
          </div>
        </div>
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
