import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderOne from "../wrappers/header/HeaderOne";
import FooterOne from "../wrappers/footer/FooterOne";
import ScrollToTop from "../components/scroll-to-top";
import Modal from "react-modal";
import { deleteFromCart } from "../store/slices/cart-slice"; // Import action to delete item
import { setCurrency } from "../store/slices/currency-slice";
import "./ggs.scss";

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
}) => {
  const { cartItems } = useSelector((state) => state.cart); // Access Redux cart state
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const currency = useSelector((state) => state.currency);

  useEffect(() => {
    console.log("��� ~ cartItems:", currency);
  }, [currency]);
  const handleRemoveItem = (cartItemId) => {
    dispatch(deleteFromCart(cartItemId)); // Dispatch action to remove item
  };

  const handleSendEmail = () => {
    const cartDetails = cartItems.map((item) => `- ${item.name}`).join("\n");
    const emailBody = `Hi, my name is [yourName],\n\nHere are the items that I would like to get a sample of:\n\n${cartDetails}\n\nMy business is [yourBusinessName]. Feel free to call me at [yourPhoneNumber].\n\nThank you!`;
    window.location.href = `mailto:Premiumqualityllc@outlook.com?subject=Sample Request&body=${encodeURIComponent(
      emailBody
    )}`;
  };

  const handleSendText = () => {
    const cartDetails = cartItems.map((item) => `- ${item.name}`).join("\n");
    const textBody = `Hi, my name is [yourName]. Here are the items that I would like to get a sample of:\n\n${cartDetails}\n\nMy business is [yourBusinessName]. Feel free to call me at [yourPhoneNumber]. Thank you!`;
    window.location.href = `sms:6612827809?body=${encodeURIComponent(textBody)}`;
  };

  return (
    <Fragment>
      <HeaderOne
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
      />
      {children}
      <FooterOne
        backgroundColorClass="bg-gray"
        spaceTopClass="pt-100"
        spaceBottomClass="pb-70"
      />

      {currency.currencyName === "isMobileMenuOpen" ? null : (
        <Fragment>
          <ScrollToTop />

          {/* Floating Cart Button */}
          {cartItems.length > 0 && (
            <button
              aria-label="Open Cart Modal"
              type="button"
              className="scroll-top-cart"
              onClick={() => {
                setIsModalOpen(true);
                dispatch(setCurrency("isCartDetailsModalOpen"));
              }}
            >
              🛒({cartItems.length})
            </button>
          )}
        </Fragment>
      )}

      {/* Modal for Cart */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          dispatch(setCurrency("isCartDetailsModalClose"));
        }}
        contentLabel="Cart Modal"
        className="cart-modal"
        overlayClassName="cart-modal-overlay"
      >
        <button
          onClick={() => {
            setIsModalOpen(false);
            dispatch(setCurrency("isCartDetailsModalClose"));
          }}
          className="close-modal-btn"
        >
          ✕
        </button>
        <h2>Your Cart</h2>
        <table className="cart-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item) => (
              <tr key={item.cartItemId}>
                <td>{item.name}</td>
                <td>
                  <button
                    className="remove-item-btn"
                    onClick={() => handleRemoveItem(item.cartItemId)}
                  >
                    ✕
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className="cart-modal-actions"
          style={{ display: "flex", gap: "8px" }}
        >
          <button onClick={handleSendEmail} className="email-cart-btn">
            Request by Email
          </button>
          <button onClick={handleSendText} className="text-cart-btn">
            Request by Text
          </button>
        </div>
      </Modal>
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.node,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default LayoutOne;
