import React from "react";
import "./Testimonials.scss";
import isMobile from "is-mobile";

const isMobileView = isMobile();
const Testimonials = () => {
  const testimonials = [
    {
      quote:
        "❝Amazing product! The ability to adjust the sugar and calorie levels using the scooper is a game-changer. Highly recommend!❞",
      author: "Anna",
    },
    {
      quote: `❝The texture is super smooth, and the aroma is amazing! It's just what I needed to make my favorite boba tea at home.❞`,
      author: "Chen",
    },
    {
      quote:
        "❝Tried these for the first time, and I'm never going back to other brands.❞",
      author: "Erin",
    },
  ];

  return (
    <div className="testimonials-container">
      <h2 className="text-center mb-4">Testimonials</h2>
      <div className="d-flex justify-content-around flex-wrap">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card text-center" key={index}>
            <p className="quote">
              <i className="fas"></i> {testimonial.quote}{" "}
            </p>
            <p className="author">- {testimonial.author}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
