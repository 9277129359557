import React, { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "./temp.scss";

const BobaPostsNoSidebar = () => {
  const blogPosts = [
    {
      id: 1,
      title: "Professional Grade Boba Pearls (READY IN 5 MINUTES OR LESS)",
      is24oz: false,
      description:
        "Uncrushable, lower calorie, made with simple and natural ingredients, no added sugar, and cooks so fast like the Flash made it, you will also feel like the professional boba maker you are!",
      image: "/assets/img/yt/boba_pearls.png",
      videoUrl: "https://www.youtube.com/embed/570gksuTtxk",
      instructions: [
        {
          label:
            "Add 3 cups of water per 1 cup of boba pearls in a decent size pot.",
          timeStamp: "21",
        },
        {
          label: "Turn on the cooker/stove until the water boils.",
          timeStamp: "38",
        },
        {
          label: "Add the boba pearls to the boiling water.",
          timeStamp: "53",
        },
        {
          label: "Cook boba pearls for 3 minutes at the HIGHEST heat.",
          timeStamp: "68",
        },
        {
          label: "Stir the pot every 2 minutes.",
          timeStamp: "88",
        },
        {
          label:
            "After 3 minutes in the highest heat, cook in the medium heat for 2 minutes.",
          timeStamp: "99",
        },
        {
          label:
            "After cooking, remove the boba pearls from the pot, and strain them.",
          timeStamp: "117",
        },
        {
          label:
            "Add sugar or honey to the boba pearls, if preferred to be sweet. Can also add into beverages like milk tea, coffee, or other desserts.",
          timeStamp: "132",
        },
        {
          label: "Store and reseal the boba pearls.",
          timeStamp: "156",
        },
      ],
    },
    {
      id: 2,
      title: "Oreo Ice Cream Boba Pearls Dessert (READY IN 5 MINUTES OR LESS)",
      is24oz: false,
      description:
        "This recipe is a favorite amongst customers. The soft and chewy boba pearls with the crunchy oreo bits, and the creamy ice cream, this dessert is a must try!",
      note: "Skip to step 8, if boba pearls already made and just need to add the oreo and ice cream",
      image: "/assets/img/yt/oreo_pearls.png",
      videoUrl: "https://www.youtube.com/embed/6Uad8Wacr8w",
      instructions: [
        {
          label:
            "Add 3 cups of water per 1 cup of boba pearls in a decent size pot.",
          timeStamp: "25",
        },
        {
          label: "Turn on the cooker/stove until the water boils.",
          timeStamp: "42",
        },
        {
          label: "Add the boba pearls to the boiling water.",
          timeStamp: "57",
        },
        {
          label: "Cook boba pearls for 3 minutes at the HIGHEST heat.",
          timeStamp: "72",
        },
        {
          label: "Stir the pot every 2 minutes.",
          timeStamp: "92",
        },
        {
          label:
            "After 3 minutes in the highest heat, cook in the medium heat for 2 minutes.",
          timeStamp: "103",
        },
        {
          label:
            "After cooking, remove the boba pearls from the pot, and strain them.",
          timeStamp: "121",
        },
        {
          label: "Add the oreo and ice cream to the boba pearls",
          timeStamp: "137",
        },
        {
          label: "Store and reseal the boba pearls.",
          timeStamp: "201",
        },
      ],
    },

    {
      id: 2,
      title:
        "Nutella Ice Cream Boba Pearls Dessert(READY IN 5 MINUTES OR LESS)",
      is24oz: false,
      description:
        "Want nutella and boba pearls with that creamy ice cream? Well look no further, this dessert will impress you every time!",
      note: "Skip to step 8, if boba pearls already made and just need to add the nutella and ice cream",
      image: "/assets/img/yt/nutella_pearls.png",
      videoUrl: "https://www.youtube.com/embed/9vuNDMC7PGA",
      instructions: [
        {
          label:
            "Add 3 cups of water per 1 cup of boba pearls in a decent size pot.",
          timeStamp: "25",
        },
        {
          label: "Turn on the cooker/stove until the water boils.",
          timeStamp: "42",
        },
        {
          label: "Add the boba pearls to the boiling water.",
          timeStamp: "57",
        },
        {
          label: "Cook boba pearls for 3 minutes at the HIGHEST heat.",
          timeStamp: "72",
        },
        {
          label: "Stir the pot every 2 minutes.",
          timeStamp: "92",
        },
        {
          label:
            "After 3 minutes in the highest heat, cook in the medium heat for 2 minutes.",
          timeStamp: "103",
        },
        {
          label:
            "After cooking, remove the boba pearls from the pot, and strain them.",
          timeStamp: "121",
        },
        {
          label: "Add the nutella and ice cream to the boba pearls",
          timeStamp: "136",
        },
        {
          label: "Store and reseal the boba pearls.",
          timeStamp: "192",
        },
      ],
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [videoSrc, setVideoSrc] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const handleOpenModal = (post) => {
    setCurrentPost(post);
    setVideoSrc(post.videoUrl); // Initialize video URL
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentPost(null);
    setVideoSrc("");
    setLoading(false);
  };

  const handleTimestampClick = (timeStamp) => {
    if (timeStamp) {
      setLoading(true); // Start loading

      // Stop the video by resetting the iframe's src
      const iframe = document.querySelector("iframe");
      if (iframe) {
        iframe.src = ""; // Temporarily clear the src to stop the video
      }

      // Update the video URL with the new timestamp
      const url = new URL(currentPost.videoUrl);
      url.searchParams.set("rel", "0");
      url.searchParams.set("vq", "hd1080p");
      url.searchParams.set("start", timeStamp); // Add the start parameter
      setTimeout(() => {
        setVideoSrc(url.toString()); // Update the iframe with the new src
        setLoading(false); // Stop loading after the update
      }, 50); // Add a short delay to ensure the video stops before reloading
    }
  };

  return (
    <Fragment>
      <div className="row row2">
        {blogPosts.map((post) => (
          <div className="col-lg-4 col-md-6 col-sm-12" key={post.id}>
            <div
              className="blog-wrap-2 mb-30"
              onClick={() => handleOpenModal(post)}
              style={{ cursor: "pointer" }}
            >
              <div className="blog-img-2">
                <img
                  src={process.env.PUBLIC_URL + post.image}
                  alt={post.title}
                  style={{ display: "block", width: "100%", height: "auto" }}
                />
              </div>
              <div className="blog-content-2 minHeight3">
                <h4>{post.title}</h4>
                <p>{post.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal
        show={showModal}
        size="xl"
        onHide={handleCloseModal}
        className="modal-top"
      >
        {currentPost && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{currentPost.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="temtemp">
              {/* YouTube Video Embed */}
              <div className="video-wrapper mb-4">
                {loading ? (
                  <div className="loading-container">
                    <CircularProgress />
                  </div>
                ) : (
                  <iframe
                    width="100%"
                    height="400"
                    // if the videoSrc doesn't have  url.searchParams.set("vq", "hd1080"); then addd it
                    src={
                      videoSrc
                    }
                    title={currentPost.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </div>

              {/* Render Instructions */}
              {!currentPost.is24oz && (
                <div className="mb-4 text-center">
                  <button
                    className="btn btn-primary"
                    style={{
                      borderRadius: "8px",
                      padding: "12px 20px",
                      fontSize: "16px",
                    }}
                    disabled={true}
                  >
                    Please throw away the oxygen absorber once package has been
                    opened.
                  </button>
                </div>
              )}

              <div className="mb-4 text-center">
                <button
                  className="btn btn-primary"
                  style={{
                    borderRadius: "8px",
                    padding: "12px 20px",
                    fontSize: "16px",
                  }}
                  disabled={true}
                >
                  Every instruction below can clicked to forward the video to
                  the respective instruction time stamp. You will have to click
                  play again.
                </button>
              </div>
              <div>
                {currentPost.note && (
                  <div className="alert alert-info" role="alert">
                    <strong>Note: {currentPost.note}</strong>
                  </div>
                )}
              </div>
              <FormGroup>
                {currentPost.instructions.map((instruction, index) => (
                  <FormControlLabel
                    className={"bottomStyleMargin"}
                    key={index}
                    control={<Checkbox color="success" />}
                    label={
                      <span
                        className={"bottomStyleMargin"}
                        style={{
                          cursor: instruction.timeStamp ? "pointer" : "default",
                          color: instruction.timeStamp ? "#007bff" : "inherit",
                        }}
                        onClick={() =>
                          instruction.timeStamp &&
                          handleTimestampClick(instruction.timeStamp)
                        }
                      >
                        {`${index + 1}. ${instruction.label}`}
                      </span>
                    }
                  />
                ))}
              </FormGroup>
            </Modal.Body>
          </>
        )}
      </Modal>
    </Fragment>
  );
};

export default BobaPostsNoSidebar;
