import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import "swiper/swiper.min.css";
import "./slider.scss"

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const SlidingImagesPage = () => {
    const images = [
        "/assets/img/temp/image4.png",
        "/assets/img/temp/image3.png",
        "/assets/img/temp/image1.png",
        "/assets/img/temp/image2.png",
    ];

    return (
        <div style={{ maxWidth: "1280px", margin: "0 auto", padding: "2rem" }}>
            <Swiper
                spaceBetween={30}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 3500 }}
                loop={true}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            style={{ width: "100%", borderRadius: "10px" }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default SlidingImagesPage;
