import {EffectFade} from 'swiper';
import Swiper, {SwiperSlide} from "../../components/swiper";
import heroSliderData from "../../data/hero-sliders/hero-slider-one.json";
import HeroSliderOneSingle from "../../components/hero-slider/HeroSliderOneSingle.js";

const params = {
    effect: "fade",
    fadeEffect: {
        crossFade: true
    },
    modules: [EffectFade],
    loop: true,
    speed: 1000,
    navigation: true
};


const HeroSliderOne = () => {
    return (
        <div className="slider-area">
            <div className="slider-active nav-style-1">
                {heroSliderData && (
                    <HeroSliderOneSingle
                        data={heroSliderData[0]}
                    />
                )}
            </div>
        </div>
    );
};

export default HeroSliderOne;
