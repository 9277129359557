import PropTypes from "prop-types";
import isMobile from "is-mobile"

const isMobileView = isMobile();
const HeroSliderOneSingle = ({data}) => {
    return (
        <div className="single-slider slider-height-1 bg-gradient-orange">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-6">
                        <div className="slider-content slider-animated-1">
                            {isMobileView ? <h1
                                className="animated"
                                style={{
                                    background: "black",
                                    color: "white",
                                    padding: "10px",
                                    borderRadius: "10px",
                                }}
                            >
                                {data.title}
                            </h1> : <h1
                                className="animated"
                                style={{
                                    background: "black",
                                    color: "white",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    fontSize: "45px",
                                    maxWidth:"408px"
                                }}
                            >
                                {data.title}
                            </h1>}

                            <h3 className="animated">{data.subtitle}</h3>
                            <div className="slider-btn btn-hover">
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-6">
                        <div className="slider-single-img slider-animated-1">
                            <img
                                className="animated img-fluid"
                                src={process.env.PUBLIC_URL + data.image}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HeroSliderOneSingle.propTypes = {
    data: PropTypes.shape({})
};

export default HeroSliderOneSingle;
