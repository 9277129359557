import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux"; // Import to get cart items
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import "./temp.scss";
import BobaPostsNoSidebar from "../../wrappers/blog/BobaPealrNoSidebar";
import SectionTitle from "../../components/section-title/SectionTitleTwo";
import TabProduct from "./../../wrappers/product/TabProduct";
import HeroSliderOne from "./../../wrappers/hero-slider/HeroSliderOne";
import SlidingImagesPage from "./../home/sliderImages";
import Testimonials from "./../../wrappers/hero-slider/testimonials";
// import "./ggs.scss";

const RequestSampleTab = () => {
  let { pathname } = useLocation();
  const { cartItems } = useSelector((state) => state.cart); // Get cart items from Redux

  return (
    <Fragment>
      <SEO
        titleTemplate="Request Sample"
        description="Blog of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <HeroSliderOne />
        <Testimonials />

        <div className={"bg-gradient-orange"}>
          <SlidingImagesPage />
        </div>

        <SectionTitle
          titleText="Our Available Products"
          subTitleText="Request a free sample of our products"
          positionClass="text-center"
          spaceClass="mt-55 mb-10"
        />
        <div className="blog-area pt-100 pb-100 blog-no-sidebar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tempClassClass">
                  <div className="row">
                    {/* blog posts */}
                    <TabProduct
                      spaceBottomClass="pb-60"
                      category="fashion"
                      hasTitle={false}
                      hasMarginTop={false}
                      isRequestSample={true}
                    />
                  </div>

                  {/* blog pagination */}
                  {/*<BlogPagination />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>

      {/* Floating Cart Button */}
    </Fragment>
  );
};

export default RequestSampleTab;
