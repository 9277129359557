import React, { Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { InstagramEmbed } from "react-social-media-embed";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import SectionTitleTwo from "../../components/section-title/SectionTitleTwo";
const socialMediaLinks = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/HealthTeaBoba.USA/posts/pfbid0c6PU4T9pCWfTHhJNn6moGVnXD3sFfZ1GfX5MG2QSAX5ZD5wgYRZ7V3ExAtxLGXSel",
    icon: FaFacebook,
    followers: "8.4k",
    likes: "1k",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/p/DCNjpoiNZAj/",
    icon: FaInstagram,
  },
];

const SocialMediaPage = () => {
  return (
    <Fragment>
      <SEO titleTemplate="Social Media Links" />
      <LayoutOne headerTop="visible">
        <SectionTitleTwo
          titleText="Follow us on Social Media"
          positionClass="text-center"
          spaceClass="mt-55 mb-10"
        />
        <Container className="my-5">
          <Row className="justify-content-center">
            {socialMediaLinks.map((link, index) => {
              const Icon = link.icon;
              return (
                <Col md={6} key={index} className="mb-4">
                  <Card className="shadow-sm h-100">
                    <Card.Body>
                      {/* Social Media Header */}
                      <div className="d-flex align-items-center mb-3">
                        <Icon className="me-2" size={28} />
                        <h5 className="mb-0">{link.name}</h5>
                      </div>

                      {/* Followers and Likes */}
                      <Row className="text-center mb-3">
                        {link.followers ? (
                          <Col>
                            <div className="fw-semibold">Followers</div>
                            <div>{link.followers}</div>
                          </Col>
                        ) : (
                          <Col style={{ marginTop: "40px" }}>
                            <div className="fw-semibold">{"\n"}</div>
                            <div>{"\n \n"}</div>
                          </Col>
                        )}

                        {link.likes && (
                          <Col>
                            <div className="fw-semibold">Likes</div>
                            <div>{link.likes}</div>
                          </Col>
                        )}
                      </Row>

                      {/* Embed Content */}
                      <div>
                        {link.name === "Facebook" ? (
                          <div>
                            {link.name === "Facebook" ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <iframe
                                  title="Facebook Post"
                                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHealthTeaBoba.USA%2Fposts%2Fpfbid0k8iYP7Hdu4jEMpcXawfHy5nmuXCDVdZfd2gNYi1KvWA7TkXqVNKFaCDoRmxgRZKQl&show_text=true&width=500"
                                  width="500"
                                  height="653"
                                  style={{ border: "none", overflow: "hidden" }}
                                  scrolling="no"
                                  frameBorder="0"
                                  allowFullScreen={true}
                                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                ></iframe>
                              </div>
                            ) : (
                              <InstagramEmbed url={link.url} width="100%" />
                            )}
                          </div>
                        ) : (
                          <InstagramEmbed url={link.url} width="100%" />
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </LayoutOne>
    </Fragment>
  );
};

export default SocialMediaPage;
