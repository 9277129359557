import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import "./temp.scss"; // Import the CSS file

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  const location = useLocation(); // Get the current route

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`,
      )}
    >
      <nav>
        <ul style={{marginTop: '7px'}}>
          {[
            { path: "/", label: "home" },
            { path: "/about", label: "about_us" },
            { path: "/drinks-instruction", label: "Drinks Instruction" },
            { path: "/boba-pearls-instruction", label: "Boba Pearls Instruction" },
            { path: "/digital-labels", label: "Digital Labels" },
            { path: "/social-media-links", label: "Social Media Links" },
          ].map((item) => (
            <li key={item.path} className={location.pathname === item.path ? "active" : ""}>
              <Link to={process.env.PUBLIC_URL + item.path}>{t(item.label)}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
